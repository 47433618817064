<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'
import { getAction } from '@/command/netTool'
import apiTool from '@/command/apiTool'

const getChildren = function getChildren(children) {
  return children.map(e => {
    return {
      ...e,
      label: e.name,
      value: `${e.id}`,
      children: e.children ? getChildren(e.children) : []
    }
  })
}

export default {
  name: 'mainDetail',
  data() {
    return {
      ...api.command.getState(),
      detail: {},
      // 区域类型
      cascadeData: []
    }
  },
  mounted() {
    getAction('/api/region/tree').then(e => {
      if (e.code == 200) {
        this.cascadeData = getChildren(e.data)
      } else {
        this.$message.error(res.msg)
      }
    })
    const { id } = this.$route.query
    id &&
      api.command.getDetail
        .call(this, {
          url: `/firm/view/${id}`
        })
        .then(result => {
          this.detail.addrCopy = `经度：${result.lon} 纬度：${result.lat}`
          let areaCodeArr = [result.provinceCode, result.cityCode, result.areaCode, result.townCode, result.villageCode]
          this.detail.area = areaCodeArr
        })
  },
  methods: {
    getForm1() {
      return {
        title: '企业信息',
        type: 'cardForm',
        gutter: 15,
        data: [
          {
            name: '企业名称',
            type: 'input',
            cols: 12,
            key: 'firmName',
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          },
          {
            name: '法定代表人',
            type: 'input',
            cols: 12,
            key: 'fddbr',
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          },
          {
            name: '统一社会信用代码',
            type: 'input',
            cols: 12,
            key: 'xydm',
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          }
          //   {
          //     name: '主体编码',
          //     type: 'input',
          //     cols: 12,
          //     key: 'firmCode'
          //   }
        ]
      }
    },

    getForm2() {
      return {
        title: '联系方式',
        type: 'cardForm',
        gutter: 15,
        data: [
          {
            name: '联系人',
            type: 'input',
            cols: 12,
            key: 'lxr',
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          },
          {
            name: '手机',
            type: 'input',
            cols: 12,
            key: 'sj',
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          },
          {
            name: '邮政编码',
            type: 'input',
            cols: 12,
            key: 'yzbm'
          }
        ]
      }
    },

    getForm3() {
      return {
        title: '地址',
        type: 'cardForm',
        gutter: 15,
        data: [
          {
            name: '定位',
            type: 'row',
            cols: 12,
            gutter: 5,
            children: [
              {
                type: 'button',
                label: '定位',
                cols: 3,
                props: {
                  type: 'primary',
                  icon: 'environment',
                  on: {
                    click: this.onOpenMap
                  }
                }
              },
              {
                type: 'text',
                cols: 21,
                key: 'addrCopy',
                label: '定位'
              }
            ]
          },
          {
            name: '地址',
            type: 'row',
            cols: 12,
            gutter: 5,
            rules: [
              {
                required: true
              }
            ],
            children: [
              {
                type: 'cascader',
                cols: 12,
                key: 'area',
                label: '地址',
                typeData: this.cascadeData,
                placeholder: '请选择地址',
                rules: [
                  {
                    required: true,
                    type: 'array'
                  }
                ]
              },
              {
                type: 'input',
                cols: 12,
                key: 'addr',
                label: '地址',
                rules: [
                  {
                    required: true,
                    type: 'string'
                  }
                ]
              }
            ]
          }
        ]
      }
    },
    onOpenMap() {
      //   this.$message.warning('定位功能暂未开通！')
      //   return
      apiTool.openMap({
        title: '打开地图',
        onSuccess: data => {
          console.log(data)
          this.detail.lat = data.lat
          this.detail.lon = data.lng
          this.detail.addrCopy = `经度：${data.lng} 纬度：${data.lat}`
          this.$forceUpdate()
        }
      })
    },
    getSumbitData() {
      const codeText = ['province', 'city', 'area', 'town', 'village']
      let arr = document.getElementsByClassName('ant-cascader-picker-label')[0].innerHTML.split('/')
      const obj = {}
      arr.forEach((e, i) => {
        obj[codeText[i]] = e
        obj[codeText[i] + 'Code'] = this.detail.area[i]
      })
      return obj
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: data => {
            const codeData = this.getSumbitData()

            api.command[this.detail.firmId ? 'edit' : 'create']
              .call(this, {
                url: '/firm',
                params: {
                  ...data,
                  ...codeData
                },
                isPost: false
              })
              .then(result => {
                this.$router.push('/infoMain/main')
              })
          }
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => {
            this.$router.push('/infoMain/main')
          }
        }
      ]

      return {
        left
      }
    }
  },
  render() {
    return (
      <DetailFormGroup
        foot={this.getFoot()}
        form={this.detail}
        data={[this.getForm1(), this.getForm2(), this.getForm3()]}
      />
    )
  }
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
</style>
